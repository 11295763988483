import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../App.css';
import Quote from './Quote';

function App() {

    return (
        <Container>
            <Row>
                <Col>
                    <Quote></Quote>
                </Col>
            </Row>
        </Container>
    );
}

export default App;
