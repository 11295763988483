import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, DistanceMatrixService, Autocomplete } from '@react-google-maps/api';
import * as Datetime from 'react-datetime';
import business from 'moment-business';
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();
class Quote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            guests: 0,
            type: '',
            distance: {value: 0},
            center: {
                lat: 50.665873,
                lng: 4.419619
            },
            zoom: 11,
            address: '',
            destination: null,
            delistreet: '77 chemin du lanternier, 1380 lasne',
            libraries: ['places'],
            description: '',
            email: '',
            name: '',
            telephone: '',
            terrace: '0',
            total: 0,
            availability: true,
            isWeekDay: true,
            howFindUs: '',
            types: [],
            options: []
        };
        this.autocomplete = null

        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        axios.all([
            axios.get(`https://api.deli-street.be/api/v1/options/formula`),
            axios.get(`https://api.deli-street.be/api/v1/options/origin`),
        ])
        .then(axios.spread((formula, origin) => {
            this.setState({
                types: formula.data,
                options: origin.data
            });
        }));
    }

    handleSubmit(event) {
        recaptchaRef.current.execute();
        if (this.state.availability === true) {
            const quote = {
                date: this.state.date,
                guests: this.state.guests,
                type: this.state.type,
                description: this.state.description,
                address: this.state.address,
                email: this.state.email,
                name: this.state.name,
                telephone: this.state.telephone,
                distance: this.state.distance.value / 1000,
                terrace: this.state.terrace,
                total: this.state.total,
                howFindUs: this.state.howFindUs
            };

            axios.post('https://api.deli-street.be/api/v1/quote', quote)
            .then(resp => {
                console.log(resp);
            });
        }
        event.preventDefault();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            if (target.name !== 'description' && target.name !== 'howFindUs' && target.name !== 'name' && target.name !== 'email' && target.name !== 'telephone') {
                this.calculateQuote();
            }
        });
    }

    handleDateChange(dateObj) {
        if (typeof dateObj === 'object' && dateObj.isValid() === true) {
            this.setState({
                date: dateObj.format('YYYY-MM-DD HH:mm:ss'),
                isWeekDay: business.isWeekDay(dateObj)
            }, () => {
                this.checkAvailability();
            });
        }
    }

    onLoad(autocomplete) {
        this.autocomplete = autocomplete
    }
    
    onPlaceChanged() {
        if (this.autocomplete !== null) {
            this.setState({
                address: this.autocomplete.getPlace().formatted_address,
                destination: {lat: this.autocomplete.getPlace().geometry.location.lat(), lng: this.autocomplete.getPlace().geometry.location.lng()},
                center: {
                    lat: this.autocomplete.getPlace().geometry.location.lat(),
                    lng: this.autocomplete.getPlace().geometry.location.lng()
                },
            });
        }
    }

    distanceCallback = response => {
        if (response !== null) {
            this.setState({distance: response.rows[0].elements[0].distance});
        }
    }

    calculateQuote() {
        let total = 0;
        let pricePerPerson = 0;
        let pricePerKm = 0;
        let priceTerrace = 0;
        let travellingExpenses = 0;

        axios.all([
            axios.get(`https://api.deli-street.be/api/v1/setting/${this.state.type}`),
            axios.get(`https://api.deli-street.be/api/v1/setting/distance`),
            axios.get(`https://api.deli-street.be/api/v1/setting/terrace`),
            axios.get(`https://api.deli-street.be/api/v1/setting/freekm`)
        ])
        .then(axios.spread((person, distance, terrace, freekm) => {
            pricePerPerson = Number(person.data.value);
            pricePerKm = Number(distance.data.value);
            if (this.state.terrace === true) {
                priceTerrace = Number(terrace.data.value);
            }
            if (freekm.data.value < (Number(this.state.distance.value) / 1000)) {
                travellingExpenses = ((Number(this.state.distance.value) / 1000) - freekm.data.value) * 2 * pricePerKm;
            }
            total = Number(this.state.guests) * pricePerPerson + travellingExpenses + priceTerrace;
            this.setState({total: total});
        }));
    }

    checkAvailability() {
        axios.get(`https://api.deli-street.be/api/v1/quote/availability/${this.state.date}`)
        .then((resp) => {
            this.setState({availability: resp.data.free});
        });
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ height: '200px', width: '100%' }}>
                    <LoadScript
                        id='script-loader'
                        libraries={this.state.libraries}
                        googleMapsApiKey='AIzaSyB-NZyfPaJnamhkMJnQLjOZqBzYlrqesCg'
                    >
                        <GoogleMap
                        id='example-map'
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        >
                            <Autocomplete
                                onLoad={this.onLoad}
                                onPlaceChanged={this.onPlaceChanged}
                            >
                                <input
                                type="text"
                                placeholder="Type your address"
                                style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: `240px`,
                                    height: `32px`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    left: "50%",
                                    marginLeft: "-120px"
                                }}
                                />
                            </Autocomplete>
                            {this.state.destination !== null && (
                                <Marker key='marker' position={{ lat: this.state.destination.lat, lng: this.state.destination.lng}}></Marker>
                            )
                            }
                            {
                                this.state.address !== '' && (
                                <DistanceMatrixService 
                                options={{
                                    destinations: [this.state.address],
                                    origins: [this.state.delistreet],
                                    travelMode: 'DRIVING',
                                }}
                                onLoad={this.distance}
                                callback={this.distanceCallback}/>
                            )}
                        </GoogleMap>
                    </LoadScript>
                </div>
                <Form onSubmit={this.handleSubmit}>
                    {this.state.distance.value / 1000}
                    <Form.Group controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Name" value={this.state.name} onChange={this.handleInputChange}/>
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.handleInputChange}/>
                    </Form.Group>
                    <Form.Group controlId="telephone">
                        <Form.Label>Telephone</Form.Label>
                        <Form.Control type="text" name="telephone" placeholder="telephone" value={this.state.telephone} onChange={this.handleInputChange}/>
                    </Form.Group>
                    <Form.Group controlId="date">
                        <Form.Label>Date</Form.Label>
                        <Datetime locale="fr-fr" onChange={this.handleDateChange} closeOnSelect={true}/>
                    </Form.Group>
                    <Form.Group controlId="guests">
                        <Form.Label>Guests</Form.Label>
                        <Form.Control type="number" name="guests" placeholder="Guests" value={this.state.guests} onChange={this.handleInputChange}/>
                    </Form.Group>
                    <div>
                        {this.state.types.map((type, index) => {
                            return <Form.Check inline name='type' label={type.label} type='radio' key={type.id} id={type.id} value={type.value} onChange={this.handleInputChange}/>
                        })}
                    </div>
                    <Form.Group controlId="howFindUs">
                        <Form.Label>How did you find us</Form.Label>
                        <Form.Control as="select" name="howFindUs" value={this.state.howFindUs} onChange={this.handleInputChange}>
                            <option value="">Choisissez une valeur</option>
                            {this.state.options.map((option, index) => {
                                return <option key={option.id} value={option.value}>{option.label}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <div>
                        <Form.Check inline name='terrace' label='Terrace' type='checkbox' id='terrace' value="1" onChange={this.handleInputChange}/>
                    </div>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="3" name="description" placeholder="Description" value={this.state.description} onChange={this.handleInputChange}/>
                    </Form.Group>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LeJaNEUAAAAADbA0sLu-JiOTbqzPdksaKFntZJH"
                        size="invisible"
                    />
                    <div>
                        <Button variant="primary" type="submit">
                            Calculate
                        </Button>
                        {this.state.total} €
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

export default Quote;